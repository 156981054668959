// extracted by mini-css-extract-plugin
export var EnLinkIconClassName = "index-module--EnLinkIconClassName--3vWwx";
export var banner = "index-module--banner--scQRI";
export var bannerBtnsClass = "index-module--bannerBtnsClass--uZqu3";
export var bannerClassname = "index-module--bannerClassname--lfnj7";
export var bannerDesc = "index-module--bannerDesc--O8Eoa";
export var bannerTitle = "index-module--bannerTitle--Bk6Us";
export var bannerWrapper = "index-module--bannerWrapper--4s-TJ";
export var carousel = "index-module--carousel--BlzbL";
export var carouselWrapper = "index-module--carouselWrapper--r58fV";
export var container = "index-module--container--NLqEw";
export var enDesc = "index-module--enDesc--wFFQ0";
export var enTitle = "index-module--enTitle--n2oFT";
export var itemContenWrapperClass = "index-module--itemContenWrapperClass--1onAK";
export var linkIconClassName = "index-module--linkIconClassName--jnuf0";
export var logo_wall_en = "index-module--logo_wall_en--KQO7W";
export var pageBottomCardDesc = "index-module--pageBottomCardDesc--sxN24";
export var partContent = "index-module--partContent--3ZkTi";
export var section = "index-module--section--kZg0K";
export var sectionCard = "index-module--sectionCard--xnWd3";
export var sectionCommon = "index-module--sectionCommon--AvikB";
export var sectionDesc = "index-module--sectionDesc--z9ClF";
export var sectionTitle = "index-module--sectionTitle--9zL7S";
export var tabItemClass = "index-module--tabItemClass--AWDaH";
export var tabsClass = "index-module--tabsClass--HYSJG";