/**
 * CDP
 * 英文
 */

import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/en'
import { Banner, Button, ImagePreview, TabSwitcher, TestimonyCard, PageBottomCard } from 'components/index'
import Seo from 'components/EN/Seo'
import Section from 'components/SolutionComponents/Section'
import { ButtonGroup } from 'components/Base/Button'
import NewShowCard from 'components/engines/NewShowCard'
import SceneCard from 'components/engines/SceneCard'
import SigningPlate from 'components/Solutions/SigningPlate'
import SectionCard from 'components/engines/SectionCard'
import ScrollLogoWall from 'components/EN/ScrollLogoWall'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'
import * as styles from './index.module.less'

const CDPEn: React.FC<any> = (props) => {
  const { data } = props
  const { cdpEnYaml } = data
  const isMb = useIsWindowWidthSmaller()
  const { title, description, keywords, banner, section1, section2, section3, section4, section6, section5, section7 } =
    cdpEnYaml
  const seo = { title, description, keywords }
  return (
    <Layout {...props}>
      <Seo {...seo} />
      <main className={styles.container}>
        <div className={styles.bannerWrapper}>
          <Banner
            {...banner}
            h1tag={true}
            className={classnames(styles.banner)}
            bannerTitleClassName={styles.bannerTitle}
            bannerDescClassName={styles.bannerDesc}
            bannerBtnsClass={styles.bannerBtnsClass}
          />
        </div>
        {/* section01 cdp 平台, A Real-time, Self-service, and Open CDP */}
        <Section
          className="lg:!mt-0 lg:pt-[100px]  pb-0 bg-[#f9fafc] lg:bg-[#fff]"
          title={section1?.title}
          desc={section1?.desc}
          descClassName={styles.sectionDesc}
          titleClassName={styles.sectionTitle}
        >
          <div className="lg:flex lg:justify-center md:mt-[73px] mt-[2.6rem] md:px-0 px-[2rem]">
            {section1?.stepArr?.map((item) => {
              return <NewShowCard className="lg:mx-[13px] mb-[10px] lg:mb-0" key={item?.title} data={item} />
            })}
          </div>
          <div className="flex justify-center mt-[30px] lg:mt-[60px]">
            <ButtonGroup>
              {section1.buttons.map((item, index) => {
                return (
                  <Button btnType="primary" key={index} ghost={item.ghost} href={item.href} target="_blank">
                    {item?.text}
                  </Button>
                )
              })}
            </ButtonGroup>
          </div>
          <div
            className={classnames(
              'lg:mt-[100px] pb-[30px] px-[2rem] lg:px-0 mt-[60px] whitespace-pre-wrap',
              styles.sectionCard,
            )}
          >
            <SectionCard rightClassName="lg:!pt-[4px]" dataSource={section7} />
          </div>
        </Section>

        {/* section3 选项卡,  A CDP built for Data Activation */}
        <Section
          className={classnames('lg:!mt-0 lg:pt-[70px] lg:pb-[100px] pb-0 bg-[#f9fafc]  ', styles.sectionCommon)}
          title={section3.title}
          desc={section3?.desc}
          descClassName={styles.sectionDesc}
        >
          <div className={styles.partContent}>
            <TabSwitcher
              data={section3?.dataSource}
              longTitle
              align="flex-start"
              mbShowShadow={false}
              autoWAndH
              itemContenWrapperClass={styles.itemContenWrapperClass}
              tabsClass={isMb ? null : styles.tabsClass}
              tabItemClass={isMb ? null : styles.tabItemClass}
            />
          </div>
        </Section>
        {/* section04 客户数据引擎的应用场景  You can Do More with Sensors CDP */}
        <Section
          className="lg:!mt-0 lg:!py-[70px] bg-[#F9FAFC] lg:bg-[#fff]"
          title={section4?.title}
          desc={section4?.desc}
          descClassName="lg:mt-0"
        >
          <div className="mx-[20px] pt-[26px] lg:flex lg:pt-[60px] justify-center flex-wrap lg:mx-auto lg:w-[1200px]">
            {section4?.dataSource?.map((item) => {
              return <SceneCard className="lg:mb-[26px] lg:mx-[13px] mb-[10px]" key={item?.title} data={item} />
            })}
          </div>
          <ButtonGroup className={classnames('md:mt-[34px] mt-[3rem] flex justify-center')}>
            {section4.buttons.map((item, index) => {
              return (
                <Button href={item.left_btn_href} key={index} target="_blank" btnType="primary" ghost={item.ghost}>
                  {item.left_btn_text}
                </Button>
              )
            })}
          </ButtonGroup>
        </Section>
        {/* section05 神策 CDP 赋能客户全生命周期运营 */}
        <section className={classnames(styles.section, 'lg:pb-[50px] bg-[#F9FAFC]')}>
          <div className="flex justify-center md:mt-[0px] pl-[20px] pr-[20px]">
            <TestimonyCard imgClassName=" lg:!h-full" {...section6} />
          </div>
          <div className={classnames('lg:w-[1070px] lg:px-0 mx-[auto] px-[20px] w-full', styles.carouselWrapper)}>
            <ScrollLogoWall
              className={classnames(' mt-[20px] ', styles.logo_wall_en)}
              maskVisible={false}
              title={section6.title}
              logo_wall={section6.icons}
            />
          </div>
        </section>
        {/*section02 全景图 , Sensors CDP Panorama */}
        <Section
          className={classnames(
            'lg:!mt-0 lg:pt-[70px] lg:pb-[70px] pb-0 bg-[#f9fafc] lg:bg-[#fff]',
            styles.sectionCommon,
          )}
          title={section2?.title}
          desc={section2?.desc}
          descClassName={styles.sectionDesc}
        >
          <div className="flex justify-center md:mt-[60px] mt-[2rem] px-[2rem]">
            <div className="lg:w-[1047px]">
              <ImagePreview
                language="en"
                imageUrl={section2?.img_url?.publicURL || section2?.img_url}
                alt="Customer Data Engine Application Scenario"
              />
            </div>
          </div>
          <ButtonGroup className={classnames('md:mt-[60px] mt-[3rem] flex justify-center')}>
            {section2.buttons.map((item, index) => {
              return (
                <Button href={item.left_btn_href} key={index} target="_blank" btnType="primary" ghost={item.ghost}>
                  {item.left_btn_text}
                </Button>
              )
            })}
          </ButtonGroup>
        </Section>
        {/* section6 其他平台引擎 */}
        <Section className="lg:!mt-0 lg:pt-[50px] lg:pb-[40px] pb-[3rem] bg-[#f9fafc] " title={section5?.title}>
          <SigningPlate
            className="lg:block"
            dataSource={section5}
            linkIconClassName={styles.EnLinkIconClassName}
            descClassName={styles.enDesc}
            enTitle={section5?.enTitle}
          />
        </Section>
        <div>
          <PageBottomCard
            title="Ready to Get Started？"
            desc="See how Sensors Data can help you make better customer journey"
            leftButtonText="Contact Sales"
            leftButtonHref="/form/parade/"
            descClassName={styles.pageBottomCardDesc}
            type="newBg"
          />
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    cdpEnYaml {
      title
      description
      keywords
      banner {
        title
        desc
        buttons {
          text
          href
          btnType
        }
      }
      section1 {
        title
        desc
        stepArr {
          title
          desc
          img {
            publicURL
          }
        }
        buttons {
          text
          href
          ghost
        }
      }
      section2 {
        title
        desc
        img_url {
          publicURL
        }
        buttons {
          left_btn_text
          left_btn_href
          ghost
        }
      }
      section3 {
        title
        desc
        dataSource {
          title
          reverse
          showIcon
          sortTitle
          content {
            img {
              publicURL
            }
            alt
            introduction1
            buttons {
              btnType
              href
              text
            }
            introductionList {
              content
            }
          }
        }
      }
      section4 {
        title
        desc
        dataSource {
          title
          desc
          img {
            publicURL
          }
        }
        buttons {
          left_btn_text
          left_btn_href
          ghost
        }
      }
      section6 {
        title
        text
        signature
        imgUrl {
          publicURL
        }
        imgAlt
        icons {
          publicURL
        }
      }
      section5 {
        title
        left {
          title
          bg {
            publicURL
          }
          desc
          btn_text
          btn_link
        }
        right {
          title
          bg {
            publicURL
          }
          desc
          btn_text
          btn_link
        }
      }
      section7 {
        left {
          img {
            publicURL
          }
        }
        right {
          title
          desc
          button {
            text
            href
            target
            btnType
          }
        }
      }
    }
  }
`

export default CDPEn
