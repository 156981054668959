import React from 'react'
import classnames from 'classnames'
import Button from 'components/Base/Button'
interface SectionCardProps {
  className?: string
  dataSource: dataSourceProps
  rightClassName?: string
}
interface dataSourceProps {
  left: { img: string | any }
  right: {
    title: string
    desc: string
    button: {
      text: string
      href: string
      target: string
      btnType: string
    }
  }
}

const SectionCard: React.FC<SectionCardProps> = ({ className, dataSource, rightClassName }) => {
  const { left, right } = dataSource
  return (
    <div className={classnames('lg:flex lg:w-fit mx-auto lg:py-[40px]', className)}>
      <div>
        <img className="lg:w-[410px] lg:h-[259px]" src={left.img?.publicURL || left.img} alt={right.title} />
      </div>
      <div className={classnames('lg:ml-[80px] lg:pt-[54px] lg:w-[648px] lg:px-0 px-[16px]', rightClassName)}>
        <h4 className="lg:text-left mt-[20px] lg:mt-0 text-center text-[16px] leading-[22px] lg:text-[18px] text-[#1f2d3d] lg:leading-[25px] font-medium">
          {right.title}
        </h4>
        <p className=" text-center lg:text-left mt-[10px] text-[14px] text-[#475669] leading-[20px] lg:mt-[6px]">
          {right.desc}
        </p>
        <div className="lg:mt-[40px] mt-[30px] text-center lg:text-left">
          <Button {...right.button}>{right.button.text}</Button>
        </div>
      </div>
    </div>
  )
}

export default SectionCard
