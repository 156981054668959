import React from 'react'

import classnames from 'classnames'
import * as styles from './index.module.less'

interface SectionProps {
  className?: string
  title?: string
  maskTitle?: string
  desc?: string
  children: any
  style?: React.CSSProperties
  descClassName?: string
  titleClassName?: string
  titleSpanClassName?: string
}

const Section: React.FC<SectionProps> = ({
  className,
  title,
  maskTitle,
  desc,
  children,
  style,
  descClassName,
  titleClassName,
  titleSpanClassName,
}) => {
  return (
    <section style={style} className={classnames(styles.container, 'lg:mt-[100px] pt-[60px] lg:pt-0', className)}>
      {!!title && (
        <h2 className={classnames(' lg:py-[10px] text-center  relative', titleClassName)}>
          <span
            className={classnames(
              'font-medium  z-[2] text-[#2c2c45] leading-[40px] lg:leading-[42px] relative  text-[20px] lg:text-[30px]',
              titleSpanClassName,
            )}
          >
            {title}
          </span>
          <span className=" absolute font-medium text-[#04CB94] opacity-[0.06] z-[1] leading-[40px] lg:leading-[62px] text-[40px] lg:text-[80px]  top-0 bottom-0 left-[50%] translate-x-[-50%] whitespace-nowrap">
            {maskTitle}
          </span>
        </h2>
      )}
      {!!desc && (
        <p
          className={classnames(
            'hidden lg:block mt-[16px] text-center text-[14px] text-[#2c2c45] leading-[20px]',
            descClassName,
          )}
        >
          {desc}
        </p>
      )}
      {children}
    </section>
  )
}

export default Section
