/**
 * 自适应logo墙
 */
import React, { useMemo } from 'react'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface ScrollLogoWallProps {
  className?: string
  /**
   * logo墙名称
   */
  title: string
  /**
   * logo墙 图片
   */
  logo_wall: string | { publicURL?: string }
  /**
   * 是否启用蒙层，默认开启
   */
  maskVisible?: boolean
  /**
   * 移动端 自定义 logo wall 高度，默认值无，移动端默认高度为52px
   */
  heightMb?: number
  /**
   * 反转轮播
   */
  reverse?: boolean
}

const ScrollLogoWall: React.FC<ScrollLogoWallProps> = (props) => {
  const { className = '', title, logo_wall, maskVisible = true, heightMb, reverse = false } = props
  const isMb = useIsWindowWidthSmaller()
  const height = useMemo(() => {
    if (isMb && heightMb) return heightMb
  }, [isMb])
  return (
    <div
      className={classnames(styles.logoWall, { [styles.whiteBg]: maskVisible, [styles.reverse]: reverse }, className)}
      style={{ height: height }}
    >
      <div className={styles.enLogoWall}>
        <img
          className={classnames(styles.enLogoWallImg, 'en-logo-wall')}
          src={(logo_wall as { publicURL?: string })?.publicURL || (logo_wall as string)}
          alt={title}
        />
        <img
          className={classnames(styles.enLogoWallImg, 'en-logo-wall')}
          src={(logo_wall as { publicURL?: string })?.publicURL || (logo_wall as string)}
          alt={title}
        />
      </div>
    </div>
  )
}
export default ScrollLogoWall
