import React from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface dataProps {
  title: string
  desc: string
  img: any
}

interface SceneCardProps {
  className?: string
  data?: dataProps
}

const SceneCard: React.FC<SceneCardProps> = ({ className, data }) => {
  return (
    <div
      className={classnames(
        styles.card,
        'py-[20px] px-[18px] lg:py-[30px] lg:px-[20px]  lg:w-[344px] lg:h-[170px]',
        className,
      )}
    >
      <div className="flex items-center">
        <div>
          <img className="w-[34px] h-[34px]" src={data?.img?.publicURL || data?.img} alt={data?.title} />
        </div>
        <h3 className="text-[16px] lg:text-[18px] font-medium text-[ #1F2D3D] ml-[16px]">{data?.title}</h3>
      </div>
      <div className="mt-[10px] text-[14px] leading-[20px] text-[#475669]">{data?.desc}</div>
    </div>
  )
}

export default SceneCard
