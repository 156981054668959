import React from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface dataProps {
  img: any
  title: string
  desc: string
}

interface NewShowCardProps {
  className?: string
  data?: dataProps
}
const NewShowCard: React.FC<NewShowCardProps> = ({ className, data }) => {
  return (
    <div
      className={classnames(
        styles.card,
        'bg-[#fff] rounded-[4px] lg:w-[343px] lg:h-[360px] px-[20px] pt-[20px] pb-[16px] lg:px-0 lg:py-0 ',
        className,
      )}
    >
      <div className="lg:block flex items-center">
        <div className="lg:py-[50px] lg:text-center">
          <img
            className="w-[48px] h-[40px] lg:w-[160px] lg:h-[130px]"
            src={data?.img?.publicURL || data?.img}
            alt={data?.title}
          />
        </div>
        <h3 className="lg:px-[28px] ml-[16px] lg:ml-0 text-center text-[16px] lg:text-[18px] font-medium text-[#181818] leading-[22px] lg:leading-[25px]">
          {data?.title}
        </h3>
      </div>
      <div className="mt-[6px]  lg:px-[28px] text-left lg:text-center text-[14px] lg:mt-[8px] text-[#475669] leading-[20px]">
        {data?.desc}
      </div>
    </div>
  )
}

export default NewShowCard
