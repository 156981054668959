import React, { useContext, useEffect, useState, useMemo } from 'react'
import Button from 'components/Base/Button'
import * as styles from './index.module.less'
import classnames from 'classnames'
// import { isPc } from 'utils/utils'
import useIsWindowWidthSmaller from '../../../hooks/useWindowWidth'

interface SigningPlateProps {
  className?: string
  dataSource: any
  /**点击跳转后面的icon的className */
  linkIconClassName?: any
  descClassName?: string
  enTitle?: Array<string>
}

const SigningPlate: React.FC<SigningPlateProps> = ({
  className,
  dataSource,
  descClassName,
  linkIconClassName,
  enTitle = [],
}) => {
  const { left, right } = dataSource
  const isMb = useIsWindowWidthSmaller()
  const leftCardBg = isMb ? undefined : { backgroundImage: `url(${left.bg.publicURL})` }
  const rightCardBg = isMb ? undefined : { backgroundImage: `url(${right.bg.publicURL})` }
  // const [isPcFlag, setIsPcFlag] = useState<boolean>(true)
  // useEffect(() => {
  //   !isPc() && setIsPcFlag(false)
  // }, [])
  return (
    <div
      className={classnames(
        styles.container,
        'lg:h-[400px] h-[188px] py-[22px] px-[10px] lg:px-0 lg:py-[60px] bg-[#F9FAFC]',
        className,
      )}
    >
      <div className="h-full lg:w-[1200px] lg:mx-auto flex justify-between">
        <div
          className="left-card-outside w-[50%] lg:w-[48%] lg:h-[280px] lg:shadow-[0_2px_10px_0_rgb(193,208,211,42%)] "
          // style={{ background: isPcFlag && 'linear-gradient(303deg, #DBFFF5 0%, #FFFFFF 100%)' }}
        >
          <div className="h-full   left-card" style={leftCardBg}>
            <a href={left?.btn_link} target="_blank">
              <h3 className=" pt-[28px] min-h-[74px] lg:h-auto lg:pt-[36px] text-[16px] lg:text-[18px] font-medium text-[#1f2d3d]">
                {left?.title}
                {!!enTitle.length && (
                  <span className="text-[12px] font-normal lg:pl-[10px] min-h-[32px] lg:min-h-0 inline-block leading-[16px]">
                    {enTitle?.[0]}
                  </span>
                )}
              </h3>
              <p
                className={classnames(
                  'hidden lg:block lg:min-h-[80px] w-[312px] text-[14px] text-[#475669] leading-[20px] mt-[14px]',
                  descClassName,
                )}
              >
                {left?.desc}
              </p>
              <p
                className={classnames('button-wrapper flex items-center  lg:mt-[54px]', {
                  'mt-[22px]': !enTitle?.length,
                  'mt-[6px]': !!enTitle?.length,
                })}
              >
                {/* <Button btnType="link" size="small" className="text-left px-0 w-auto" href={left?.btn_link}> */}
                <Button btnType="link" size="small" className="text-left px-0 w-auto">
                  {left?.btn_text}
                </Button>
                <span className={classnames('inline-block arrows-icon', linkIconClassName)}></span>
              </p>
            </a>
          </div>
        </div>
        <div
          className="right-card-outside w-[50%] lg:w-[48%] lg:shadow-[0_2px_10px_0_rgb(193,208,211,42%)]"
          // style={{ background: isPcFlag && 'linear-gradient(303deg, #DBFFF5 0%, #FFFFFF 100%)' }}
        >
          <div className="h-full right-card" style={rightCardBg}>
            <a href={right?.btn_link} target="_blank">
              <h3 className=" pt-[28px] min-h-[74px] lg:h-auto lg:pt-[36px] text-[16px] lg:text-[18px] font-medium text-[#1f2d3d]">
                {right?.title}
                {!!enTitle.length && (
                  <span className="text-[12px] inline-block leading-[16px] font-normal min-h-[32px] lg:min-h-0 lg:pl-[10px]">
                    {enTitle?.[1]}
                  </span>
                )}
              </h3>
              <p
                className={classnames(
                  'hidden lg:block lg:min-h-[80px]  w-[312px] text-[14px] text-[#475669] leading-[20px] mt-[14px]',
                  descClassName,
                )}
              >
                {right?.desc}
              </p>
              <p
                className={classnames('button-wrapper flex items-center  lg:mt-[54px]', {
                  'mt-[22px]': !enTitle?.length,
                  'mt-[6px]': !!enTitle?.length,
                })}
              >
                {/* <Button btnType="link" size="small" className="text-left px-0" href={right?.btn_link}> */}
                <Button btnType="link" size="small" className="text-left px-0">
                  {right?.btn_text}
                </Button>
                <span className={classnames('inline-block arrows-icon', linkIconClassName)}></span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SigningPlate
